
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>心动丨资讯</h3>
      <span>___</span>
      <p>INFORMATION</p>
    </div>
    <div class="content">
      <h5>心动丨资讯</h5>
      <a
        v-for="(v, index) in Consulting"
        :key="index"
        :href="v.href"
        class="news_c"
      >
        <img class="news_img" :src="v.urlimg" />
        <div>
          <div class="news_n">
            <p>{{v.time}} <span> ___</span></p>
            <div class="news_content">
              <h6>{{v.title}}</h6>
              <p>
               {{v.content}}
              </p>
            </div>
          </div>
          <div class="news_s">
            <p class="author">{{v.author}}</p>
            <p class="see">查看更多</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Consulting: [
        {
          href: "https://mp.weixin.qq.com/s/LbVmBA-uL5t7HfSZpvg-fA",
          title: "专业·赋能丨微心动助力星级酒店pc官网升级评星",
          content:
            "酒店星评工作的开展 少不了pc官网的升级星级酒店pc官网升级可得抓紧了 别担心！ 微心动「pc官网产品方案」助力星级酒店评星！ 更准确、更快速、更全面……",
          author: "作者：微心动运营乌托邦  2022-09-02 17:00",
          time: "09-02",
          urlimg:require('../../assets/images/Consulting01.png')
        },
        {
          href: "https://mp.weixin.qq.com/s/kO8H5uThTodLpOt8iv7MBw",
          title: "全新酒店收益增长模式，微心动&美樾集团赋能小微酒店增量运营！",
          content:
            "2022年8月25日，成都微心动科技有限公司与美樾酒店集团就助力小微酒店增量运营全面达成合作，共同开拓国内酒旅市场……",
          author: "作者：微心动运营乌托邦  2022-08-26 17:00",
          time: "08-26",
          urlimg:require('../../assets/images/Consulting02.png')
        },
        {
          href: "https://mp.weixin.qq.com/s/z1lSFHa3wmePBSkGCvardA",
          title: "餐饮数字化会员营销解决方案新利器：微心动SCRM一体化会员营销中台",
          content:
            "1、降低餐饮预订平台佣金   目前餐饮预订平台抽佣比例少则20%，高则26%，高额的平台佣金让商家收益大打折扣。微心动SCRM会员营销体系通过搭建门店……",
          author: "作者：微心动运营乌托邦  2022-08-12 17:00",
          time: "08-12",
          urlimg:require('../../assets/images/Consulting03.png')
        },
        {
          href: "https://mp.weixin.qq.com/s/P8q1CsZfiALGvcdoiqElsg",
          title: "园区管理强劲引擎——微心动Ebox解决方案",
          content:
            "2022年楼宇资管市场行业迎来深度调整和深层分化，数字化不仅是“数字中国”战略下的选择趋势，也是楼宇资管市场发展转变的关键，更是决胜未来的胜负手……",
          author: "作者：微心动运营乌托邦  2022-07-29 17:00",
          time: "07-29",
          urlimg:require('../../assets/images/Consulting04.png')
        },
        {
          href: "https://mp.weixin.qq.com/s/SRUGLF5E-vhHfP9jTR8OAQ",
          title: "挑战与机遇并存——企业客户&TMC直连,酒店拓展商旅市场的新路径",
          content:
            "疫情常态化下，作为酒店核心业务之一的旅客业务板块陷入了长期的波动之中。而服务商务差旅对应的企业客户更成为酒店运营的重中之重。随着疫情常态化……",
          author: "作者：微心动运营乌托邦  2022-07-20 17:00",
          time: "07-20",
          urlimg:require('../../assets/images/Consulting05.png')
        },
        {
          href: "https://mp.weixin.qq.com/s/mOGQlUrbbtfGE4r5DLOvKA",
          title: "酒旅市场消费信心恢复巨大，微心动助力酒店618期间再创佳绩！",
          content:
            "今年的“618”似乎比往年“安静”，在疫情持续的倒逼下，酒店业也不断加速线上数字化转型，并强化私域流量运营，这也使得酒店业在此次“618”期间收获颇丰……",
          author: "作者：微心动运营乌托邦  2022-07-01 17:00",
          time: "07-01",
          urlimg:require('../../assets/images/Consulting06.png')
        },
        {
          href: "https://mp.weixin.qq.com/s/y3Rw3LKWFVXYM9gQ_PTp8g",
          title: "打造农业新生态！——微心动与四川省供销科技产业集团正式签约",
          content:
            "2022年6月1日，成都微心动科技有限公司与四川省供销科技产业集团就助力智慧农业数字化发展达成全面合作……",
          author: "作者：微心动运营乌托邦  2022-06-02 17:00",
          time: "06-02",
          urlimg:require('../../assets/images/Consulting07.png')
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/consult03.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  span {
    font-weight: 600;
    font-size: 0.4rem;
  }
  p {
    width: 7.43rem;
    margin: 1em auto 0;
    font-size: 0.36rem;
    line-height: 0.3rem;
  }
}
.content {
  color: #333;
  margin-top: 1.36rem;

  padding: 0 2rem;
  h5 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.58rem;
  }
  .news_c {
    display: flex;
    padding: 0.6rem 0;
    border-bottom: 0.01rem solid #eeeeee;
    .news_img {
      width: 5.98rem;
      height: 2.56rem;
    }
    .news_n {
      padding: 0.31rem 0 0 0.5rem;
      display: flex;
      p {
        color: #333;
        font-size: 0.28rem;
        span {
          color: #aaa;
        }
      }
      .news_content {
        width: 6.32rem;
        padding-left: 0.2rem;
        h6 {
          font-size: 0.27rem;
          line-height: 0.41rem;
          padding-bottom: 0.16rem;
          color: #333;
        }
        p {
          font-size: 0.18rem;
          line-height: 0.27rem;
          color: #666666;
        }
      }
    }
    .news_s {
      padding: 0rem 0 0 0.5rem;
      display: flex;
      justify-content: space-between;
      .author {
        font-size: 0.18rem;
        padding-top: 0.5rem;
        color: #666666;
      }
      .see {
        font-size: 0.18rem;
        padding-top: 0.5rem;
        color: #ffc53d;
      }
    }
  }
}
</style>
